'use client'
import React, {Component, ReactNode, SVGProps, useEffect, useMemo, useRef, useState} from 'react'
import { CSSProperties } from 'react';
import clsx from 'clsx'
import {
  motion,
  useAnimationFrame,
  useInView,
  useMotionValue,
  useSpring,
  useTransform,
} from 'framer-motion'

import {ContainerComponent} from './ContainerComponent'


type ReviewEntry = {
  title: string,
  body: string,
  author: string,
  rating: number
};

const reviews: ReviewEntry[] = [
  {
    title: 'Great rates!',
    body: 'Brya Insurance has been awesome, great rates and customer service!',
    author: 'Jason F',
    rating: 5,
  },
  {
    title: 'Cut my rate in half.',
    body: 'I was paying $300 to (lizard company), Brya Insurance was less than half.',
    author: 'Trinity B',
    rating: 5,
  },
  {
    title: 'You are a great friend',
    body: 'Eres un gran amigo.',
    author: 'Francisco A.',
    rating: 5,
  },
  {
    title: 'Long time customer.',
    body: 'I have been buying insurance with them for close to forty years now.  They are always very helpful and they have found me some great rates over the years.',
    author: 'Peter P',
    rating: 5,
  },
  {
    title: 'Friendly and knowledgeable!',
    body: 'I like their friendly and knowledgeable approach to my insurance needs.  I always recommend them to family and friends.',
    author: 'Peter P',
    rating: 5,
  },
  {
    title: 'Saving thousands a year.',
    body: 'Brya saved me over 50% on my auto insurance.  Had a policy with one of the big insurance companies for me, my sons, and their cars.  Brya was able to show how moving to another company and putting one of my sons on their own policy could save me thousands a year !',
    author: 'Bob',
    rating: 5,
  },
  {
    title: 'Provided incredible service',
    body: 'Had them for years and they provided incredible service! Cameron and Willie were great to work with.',
    author: 'Griffin D',
    rating: 5,
  },
  {
    title: 'Fantastic support and lowest possible rates',
    body: 'Come here for fantastic support and the lowest possible rates. These people will do everything they can to help you!!!',
    author: 'Mohammad F',
    rating: 5,
  },
  {
    title: 'Saved us good money!',
    body: 'Brayden really helped us out and saved us good money',
    author: 'Recon M',
    rating: 5,
  },
  {
    title: 'They made us feel comfortable.',
    body: 'It was more then a relief working with byra insurance! They made us feel comfortable, excepted, and they were very affordable. Their professionalism was beyond our expectations.',
    author: 'Flint L',
    rating: 5,
  },
  {
    title: 'Excellent and timely response.',
    body: 'I have Erie Insurance through Brya.  Excellent and timely response from last Thursday’s storm.',
    author: 'Mary V',
    rating: 5,
  },
]

function StarIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  )
}

class StarRating extends Component<{ rating: any }> {
  render() {
    let {rating} = this.props;
    return (
        <div className="flex">
          {[...Array(5).keys()].map((index) => (
              <StarIcon
                  key={index}
                  className={clsx(
                      'h-5 w-5',
                      rating > index ? 'fill-cyan-500' : 'fill-gray-300'
                  )}
              />
          ))}
        </div>
    )
  }
}

type ReviewProps = {
  title: string,
  body: string,
  author: string,
  rating: number,
  className?: string,
  props?: ReactNode
};


function Review({ title, body, author, rating, className, ...props } : ReviewProps) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s']
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ]
  }, [])

  return (
    <figure
      className={clsx(
        'animate-fade-in rounded-3xl bg-white p-6 bhopacity-0 shadow-md shadow-gray-900/5',
        className
      )}
      style={{ animationDelay }}
      {...props}
    >
      <blockquote className="text-gray-900">
        <StarRating rating={rating} />
        <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
          {title}
        </p>
        <p className="mt-3 text-base leading-7">{body}</p>
      </blockquote>
      <figcaption className="mt-3 text-sm text-gray-600 before:content-['–_']">
        {author}
      </figcaption>
    </figure>
  )
}

function splitArray(array : Array<ReviewEntry>, numParts: number): Array<Array<ReviewEntry>> {
  let result =  new Array<Array<ReviewEntry>>;
  for (let i = 0; i < array.length; i++) {
    //console.log('Split' + i.toString())
    let index = i % numParts
    if (!result[index]) {
      result[index] = [];
    }
    let reviewEntry = array[i]
    if (reviewEntry) {
      result[index].push(reviewEntry)
    }
  }
  return result
}

type ReviewColumnProps = {
  className?: string,
  reviews?: Array<ReviewEntry>,
  reviewClassName: Function,
  msPerPixel?: number
};

function ReviewColumn({className, reviews, reviewClassName, msPerPixel} :ReviewColumnProps)
   {
  let columnRef = useRef<HTMLDivElement>(null);
  let [columnHeight, setColumnHeight] = useState(0)
  if (!msPerPixel) {
    msPerPixel =0;
  }
  if (!reviews) {
    reviews = new Array<ReviewEntry>
  }
  let duration = `${columnHeight * msPerPixel}ms`
  let reviewLength: number = 0;
  if (reviews) {
    reviewLength = reviews.length
  }
    useEffect(() => {
      let resizeObserver = new window.ResizeObserver(() => {
        if (columnRef && columnRef.current ) {
          setColumnHeight(columnRef.current.offsetHeight)
        }
      })

      if (columnRef && columnRef.current ) {
        resizeObserver.observe(columnRef.current)
      }

      return () => {
        resizeObserver.disconnect()
      }
    }, [])

  const marqueeStyle = { "--marquee-duration": duration } as React.CSSProperties;

  return (
    <div
      ref={columnRef}
      className={clsx('animate-marquee space-y-8 py-4', className)}
      style={marqueeStyle}
    >
      {reviews.concat(reviews).map((review, reviewIndex: number) => (
          <Review
              key={reviewIndex}
              aria-hidden={reviewIndex >= reviewLength}
              className={reviewClassName(reviewIndex % reviewLength)}
              {...review}
          />
      ))}
    </div>
  )
}

function ReviewGrid() {
  let containerRef = useRef<HTMLDivElement>(null);
  let isInView = useInView(containerRef, { once: true, amount: 0.4 })
  let columns: Array<Array<ReviewEntry>> = splitArray(reviews, 3)
 //bh columns = [columns[0], columns[1], splitArray(columns[2], 2)]
  columns = [columns[0], columns[1], columns[2]]

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={[...columns[0].flat(), ...columns[2].flat(), ...columns[1].flat()]}
            reviewClassName={(reviewIndex : number) =>
              clsx(
                reviewIndex >= columns[0].length + columns[2].length &&
                  'md:hidden',
                reviewIndex >= columns[0].length && 'lg:hidden'
              )
            }
            msPerPixel={10}
          />
          <ReviewColumn
            reviews={[...columns[1], ...columns[2]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex : number ) =>
              reviewIndex >= columns[1].length && 'lg:hidden'
            }
            msPerPixel={15}
          />
          <ReviewColumn
            reviews={columns[2].flat()}
            className="hidden lg:block"
            msPerPixel={10}
            reviewClassName={(reviewIndex : number ) =>
                ''
            }

          />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-50" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-50" />
    </div>
  )
}

export function Reviews() {
  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className="pt-20 pb-16 sm:pt-32 sm:pb-24"
    >
      <ContainerComponent>
            <h2
          id="reviews-title"
          className="text-3xl font-medium tracking-tight text-gray-900 sm:text-center"
        >
          Fast, Easy, and Affordable Insurance since 1955.
        </h2>
        <p className="mt-2 text-lg text-gray-600 sm:text-center">
          Are you ready to join the thousands of people Brya has helped find quality, affordable insurance coverage?
        </p>
        <ReviewGrid />
      </ContainerComponent>
    </section>
  )
}
